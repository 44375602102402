<template>
  <div class="user-select">
    <div class="input-field" @click="triggerSelect">
      <i class="material-icons prefix">face</i>
      <input id="user" type="text" v-model="login" @input="fetchUsers">
      <span class="helper-text" data-error="wrong" data-success="right">{{subtitle}}</span>
    </div>
    <div class="users-list scrollspy" ref="userslist" v-if="usersCount>0" :class="{show:showSelect}">
      <div class="row" v-for="user in users" :key="user.userid" @click="selectUser(user)">
        <div>{{ user.name1 + ' ' + user.name2 + ' ' + user.name3 + ' ' + user.login }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "UserSelect",
  props: {
    active: {type: Boolean, default: false,},
    subtitle:{type:String,default:'Пользователь'},
  },
  data() {
    return {
      users: [],
      usersCount: 0,
      login: '',
      page: 1,
      perpage: 10,
      scrollspy: null,
      showSelect: false,
    }
  },
  computed:{
    ...mapGetters('Config', ['getApiRoot',]),
  },
  methods:{
    async fetchUsers(show=true){
      if (show) this.showSelect=true;
      let self = this;
      await this.$http.get(process.env.VUE_APP_LINK + this.getApiRoot + 'orders/'+'users/?login='+this.login+'&page=' + this.page + '&perpage=' + this.perpage).then((res) => {
        if (res.status == 200 && res.data && 'count' in res.data && parseInt(res.data.count)) {
          self.users = res.data.users;
          self.usersCount = parseInt(res.data.count);
        }
      }, () => {
      });
    },
    selectUser(u) {
      this.login=u.login;
      this.showSelect=false;
      this.$emit('selected',u);
    },
    triggerSelect(){
      this.showSelect=!this.showSelect;
      if (this.usersCount==0) this.fetchUsers();
    },
    destroy() {
      this.scrollspy.destroy();
    },

  },
  async mounted(){
    this.fetchUsers(false);
  },
  updated() {
    document.addEventListener('DOMContentLoaded', function () {
      let elems = document.querySelectorAll('.scrollspy');
      this.scrollspy = M.ScrollSpy.init(elems, {});
    });
  },
  beforeDestroy() {
    this.destroy();
  },
}
</script>

<style lang="scss" scoped>
.user-select{
  position: relative;
  .users-list{
    display: none;
    z-index: 1;
    position: absolute;
    background: aliceblue;
    top: 40px;
    left:0px;
    width: 50vw;
    max-height: 70vh;
    &.show{
      display: block;
    }
    .row{
      cursor:pointer;
      margin: 3px 0 3px 0;
    }
    .row:hover{
      background: saddlebrown;
      color:white;
      font-weight: bolder;
    }
  }
}
</style>