<template>
  <div class="edit-form">
    <div class="plug" v-if="!order.orderid">
      <h5>Приказ загружается..</h5>
    </div>
    <div class="main-block" v-else>
      <h5 class="input-field">
        <i class="material-icons prefix">description</i>
        <input id="prikaz-name" type="text" v-model="order.name" :disabled="Number(order.approved)>0 && !isDirector">
        <span class="helper-text" data-error="wrong" data-success="right">Название приказа</span>
      </h5>
      <div class="row">
        <div class="input-field col s4">
          <i class="material-icons prefix">account_circle</i>
          <input id="prikaz-creator" type="text" v-model="order.creator" disabled="disabled">
          <span class="helper-text" data-error="wrong" data-success="right">Создатель</span>
          <UserSelect v-if="unlocked" @selected="setCreator($event)" subtitle="Новый создатель"></UserSelect>
        </div>
        <div class="input-field col s4">
          <i class="material-icons prefix">blur_circular</i>
          <!--          <input type="text" :value="formatDateTime(order.creationdate)" disabled="disabled">-->
          <input type="text" :value="orderNomer" :disabled="!unlocked" v-if="!unlocked">
          <input type="text" v-model="order.Nomer" v-else>
          <span class="helper-text" data-error="wrong" data-success="right">Номер приказа</span>
        </div>
        <div class="col s4 col-block">
          <i class="material-icons prefix" :class="{'blink':saveProcess}">check_circle</i>
          <div class="input-field">
            <span v-if="!signdateEditable">{{ spanSignDate }}</span>
            <Datetime v-else :format="pickerformat" type="date" :phrases="{ok:'Сохранить',cancel:'Отмена'}" :week-start="1"
                      v-model="order.signdate"
                      :min-datetime="lastDate"
                      zone="Asia/Krasnoyarsk" @close="saveDate"></Datetime>
            <span class="helper-text" data-error="wrong" data-success="right">Дата приказа</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s12">
          <i class="material-icons prefix">assignment</i>
          <textarea id="order-content" type="text" v-model="order.content"></textarea>
          <span class="helper-text" data-error="wrong" data-success="right">текст приказа</span>
        </div>
      </div>
      <div class="row">
        <OrderFile v-for="(file,ind) in order.files" :key="'file'+ind" :file="file" :can-delete="!order.approved || isDirector"
                   @file-deleted.stop="removeFile"></OrderFile>
      </div>
      <FileManager @upload-complete="fetchOrder" v-if="order.approved<2 || isDirector"></FileManager>
      <div class="row buttons mobile-flex-wrap">
        <div class="col s12 m4 l4">
          <a v-if="isDirector && !unlocked" class="waves-effect waves-orange btn btn-large brown lighten-2"
             @click="unlockOrder"><i class="material-icons">build</i><span>Разблокировать</span></a>
        </div>
        <div class="col s12 m4 l4">
          <a class="waves-effect waves-orange btn btn-large brown lighten-2" @click="saveOrder"><i
              class="material-icons">save</i><span>Сохранить</span></a>
        </div>
        <div class="col s12 m4 l4">
          <a class="waves-effect waves-orange btn btn-large brown lighten-2" @click="$router.go(-1)"><i
              class="material-icons">arrow_back</i><span>Назад</span></a>
        </div>
      </div>
      <div class="row buttons mobile-flex-wrap" v-if="Number(order.Nomer)>0 && isDirector && !unlocked && Number(order.approved)<2">
        <div class="col s12 m4 l4" v-if="Number(order.approved)<1">
          <a class="waves-effect waves-orange btn btn-large brown lighten-2" @click="approveOrder"><i
              class="material-icons">done</i><span>Утвердить</span></a>
        </div>
        <div class="col s12 m4 l4" v-if="Number(order.approved)<2">
          <a class="waves-effect waves-orange btn btn-large brown lighten-2" @click="signOrder"><i
              class="material-icons">done_all</i><span>Подписать</span></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import dateformat from "dateformat";
import FileManager from "@/components/FileManager";
import OrderFile from "@/components/OrderFile";
import UserSelect from "@/components/UserSelect";
import eventHub from "@/eventHub";
import {Datetime} from 'vue-datetime';
import {Settings, DateTime} from 'luxon';
//import luxon from 'luxon';
import 'vue-datetime/dist/vue-datetime.css';

Settings.defaultLocale = 'ru';
//Settings.defaultZone='utc';
Settings.defaultZoneName = "utc";

export default {
  name: "EditForm",
  data() {
    return {
      order: {name: '', Nomer: 0, category: 'осн', content: '', approved: 0, files: null, signer: '',},
      user: {
        login: '',
        password: '',
      },
      pickerformat: DateTime.DATE_MED,// DATETIME_MED,
      preSignDate: '',
      saveProcess: false,
      signdateEditable: false,
      lastDate: new Date().toISOString(),
      unlocked: false,
      lockdata: {},
      tooltip_instances: null,
    }
  },
  computed: {
    ...mapGetters('User', ['isLoggedIn', 'isDirector']),
    ...mapGetters('Config', ['getApiRoot',]),
    spanSignDate() {
      let d = new Date(this.order.signdate);
      return d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
    },
    orderNomer() {
      if (this.order.Nomer) {
        return '№ ' + this.order.Nomer + '-' + this.order.category
      } else return 'пока без номера';
    }
  },
  methods: {
    formatDateTime(d) {
      if (d) {
        let date = new Date(d);
        date.setHours(0, 0, 0, 0);
        return date.toISOString();
      } else return '';
    },
    fetchLastNomer() {
      let self = this;
      this.$http.get(process.env.VUE_APP_LINK + this.getApiRoot + 'orders/last_nomer').then((res) => {
        if (res.status == 200 && res.data) {
          if ('signdate' in res.data) {
            self.lastDate = self.formatDateTime(res.data.signdate.date);
            console.log(self.lastDate);
          }
        }
      }, () => {
      });
    },
    async fetchOrder() {
      let self = this;
      await this.$http.get(process.env.VUE_APP_LINK + this.getApiRoot + 'orders/' + this.$route.params.orderid).then((res) => {
        if (res.status == 200 && res.data) {
          if (!res.data.signdate) res.data.signdate = res.data.creationdate;
          //res.data.signdate=(new Date(res.data.signdate.date)).toISOString();
          res.data.signdate = res.data.signdate.substring(0, res.data.signdate.indexOf('T'));
          //(new DateTime.fromISO(res.data.signdate, {zone: 'utc'})).toISODate(); //it works too!
          self.preSignDate = res.data.signdate;
          if ('Nomer' in res.data && parseInt(res.data.Nomer)) {
            self.signdateEditable = false;
          } else {
            self.signdateEditable = true;
          }
          self.order = res.data;
        }
      }, () => {
      });
    },
    async removeFile(fileid) {
      let self = this;
      for (let i = 0; i < this.order.files.length; i++) {
        if (this.order.files[i].fileid === fileid) {
          await this.$http.delete(process.env.VUE_APP_LINK + this.getApiRoot + 'orders/' + this.$route.params.orderid + '/file/' + fileid,).then((res) => {
            if (res.status == 200 && 'message' in res.data && res.data.message == "OK") {
              self.order.files.splice(i, 1);
            }
          });
          break;
        }
      }
    },
    async saveOrder() {
      let self = this;
      if (!this.saveProcess) {
        this.saveProcess = true;
        if (this.unlocked) this.order.isDirector = this.isDirector;
        await this.$http.put(process.env.VUE_APP_LINK + this.getApiRoot + 'orders/' + this.$route.params.orderid, this.order).then((res) => {
          if (res.status == 200 && res.data) {
            self.preSignDate = self.order.signdate;
            if ('Nomer' in res.data) {
              self.order.Nomer = res.data.Nomer;
            }
            if (self.unlocked) {
              self.lastDate = self.lockdata.lastDate;
              self.signdateEditable = self.lockdata.signdateEditable;
              self.unlocked = false;
            }
          }
        }, () => {
        });
        self.saveProcess = false;
      }
    },
    async saveDate() {
      if (this.unlocked) return;
      if (this.preSignDate !== this.order.signdate) {
        let self = this;
        if (!this.saveProcess) {
          this.saveProcess = true;
          await this.$http.put(process.env.VUE_APP_LINK + this.getApiRoot + 'orders/' + this.$route.params.orderid, this.order).then((res) => {
            if (res.status == 200 && res.data) {
              self.preSignDate = self.order.signdate;
              self.saveProcess = false;
              if ('Nomer' in res.data) {
                self.order.Nomer = res.data.Nomer;
              }
            }
          }, () => {
          });
          self.saveProcess = false;
        }
      }
    },
    unlockOrder() {
      this.lockdata.lastDate = this.lastDate;
      this.lockdata.signdateEditable = this.signdateEditable;
      this.lastDate = '';
      this.signdateEditable = true;
      this.unlocked = true;
    },
    async approveOrder() {
      let self = this;
      if (this.isDirector) this.order.isDirector = this.isDirector;
      this.order.approved = 1;
      await this.$http.put(process.env.VUE_APP_LINK + this.getApiRoot + 'orders/' + this.$route.params.orderid, this.order).then((res) => {
        if (res.status == 200 && res.data) {
        }
      }, () => {
        self.order.approved = 0;
      });
    },
    async signOrder() {
      let self = this;
      if (this.isDirector) this.order.isDirector = this.isDirector;
      if (Number(this.order.approved) === 1) {
        this.order.approved = 2;
        await this.$http.put(process.env.VUE_APP_LINK + this.getApiRoot + 'orders/' + this.$route.params.orderid, this.order).then(() => {
        }, () => {
          self.order.approved = 1;
        });
      }
    },
    destroyCurrent(){
      if (this.tooltip_instances){
        this.tooltip_instances.forEach((v)=>{
          v.destroy();
        });
      }
    },
    setCreator(user){
      this.order.creator=user.shortname;
      this.order.creatorid=user.userid;
      console.log(this.order,user);
    }
  },
  async mounted() {
    await this.fetchLastNomer();
    await this.fetchOrder();
    eventHub.$on('file-deleted', this.removeFile);
    setTimeout(()=>{
      let elems = document.querySelectorAll('.tooltipped');
      this.tooltip_instances = window.M.Tooltip.init(elems, {});
    }, 0);

  },
  components: {FileManager, OrderFile, Datetime, UserSelect},
}
</script>

<style scoped lang="scss">
input[type=text]:not(.browser-default):disabled {
  color: #2c3e50;
}

input[type=text]:not(.browser-default):focus {
  border-bottom: 1px solid #a1887f !important;
  box-shadow: 0 1px 0 0 #a1887f !important;
}

.input-field .prefix.active {
  color: #a1887f !important;
}

.col-block {
  display: flex;
  align-items: flex-start;

  i {
    top: 1.6rem;
    position: relative;
    margin-right: 6px;
  }
}

@keyframes blink {
  50% {
    opacity: 0.0;
  }
}

@-webkit-keyframes blink {
  50% {
    opacity: 0.0;
  }
}

.blink {
  animation: blink 1s step-start 0s infinite;
  -webkit-animation: blink 1s step-start 0s infinite;
}

.row.buttons {
  margin-top: 20px;

  .btn {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 5px;

    i {
      width: 1.6rem;
    }
  }
}

@media (max-width: 800px) {
  .mobile-flex-wrap {
    flex-wrap: wrap;
  }
  .no-mobile {
    display: none;
  }
}

</style>