<template>
  <div class="order-file col s6 row">
    <a class="order-file-link col s8" :href="downloadLink">
      <span class="material-icons">file_download</span>
      <span>{{ file.description }}</span>
    </a>
    <div class="order-file-delete col s4" @click="deleteFile" v-if="canDelete">
      <i class="material-icons prefix">delete</i>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import eventHub from "@/eventHub";

export default {
  name: "OrderFile",
  props: {
    file: {type: Object, default: null,},
    canDelete: {type: Boolean, default: false,}
  },
  computed: {
    ...mapGetters('Config', ['getApiRoot']),
    uploadPath() {
      return process.env.VUE_APP_LINK + this.getApiRoot + 'orders';
      //return p[p.length-1]==='/'?p.substr(0,p.length-1):p;
    },
    downloadLink() {
      let c = this.file.link.split('/');
      for (let i = 0; i < c.length; i++) {
        c[i] = encodeURIComponent(c[i]);
      }
      return this.uploadPath + c.join('/');
    },
    deleteLink(){
      return process.env.VUE_APP_LINK + this.getApiRoot+ 'orders/files/'+this.file.fileid;
    }
  },
  data() {
    return {}
  },
  methods: {
    async deleteFile() {
      // await this.$http.delete(this.deleteLink)
      //     .then(r => r)
      //     .catch(r => console.log(r));

      eventHub.$emit('file-deleted', this.file.fileid);
    },
  },
  mounted() {
    let c = this.file.link.split('/');
    for (let i = 0; i < c.length; i++) {
      c[i] = encodeURIComponent(c[i]);
    }
    let p = c.join('/');
    console.log(p);
  }
}
</script>

<style scoped lang="scss">
.order-file-link {
  display: flex;
  align-items: center;

  &:hover span {
    color: brown;
  }
}

.order-file-delete {
  cursor: pointer;

  &:hover {
    color: red;
  }
}
</style>