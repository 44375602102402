<template>
  <div class="image-uploader-container" :class="{'dragging':dragging}"
       @dragenter.prevent.stop="stopExternalDragging('dragenter')"
       @dragleave.prevent.stop="stopExternalDragging('dragleave',true)"
       @drop.prevent.stop="drop($event)"
       @dragover.prevent.stop="stopExternalDragging('dragover')"
       @dragstart.prevent.stop="stopExternalDragging('dragstart',true)"
  >
    <form :action="uploadPath" class="upload-image-form">
      <label for="">Перетащите файл в эту область для загрузки</label>
      <input type="file" name="file" ref="file" @onchange="uploadFile" multiple="multiple">
      <input type="submit" value="Загрузить" @click.prevent="uploadFile(false)">
    </form>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "FileManager",
  data() {
    return {
      file: '',
      result: [],
      dragging: false,
      uploaded: false,
    }
  },
  computed:{
    ...mapGetters('Config', ['getApiRoot',]),
    uploadPath(){
      return process.env.VUE_APP_LINK + this.getApiRoot+"orders/file/upload";
    },
  },
  methods: {
    uploadFile: async function (files = false) {
      let self = this;
      if (!files) {//upload when choosed by button
        for (let i = 0; i < this.$refs.file.files.length; i++) {
          let file = this.$refs.file.files[i];
          let formData = new FormData();
          formData.append("cup", file);
          formData.append("orderid", this.$route.params.orderid);
          await self.$http.post(self.uploadPath, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
              .then(r => self.result.push(r))
              .catch(r => console.log(r));
        }
      } else {// upload drag&drop
        for (let i = 0; i < files.length; i++) {
          //files.forEach(function (elem) {
          let formData = new FormData();
          formData.append("cup", files[i]);
          formData.append("orderid", this.$route.params.orderid);
          await self.$http.post(self.uploadPath, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
              .then(res => self.result.push(res))
              .catch(r => console.log(r));
        }
      }
      this.uploaded = true;
      this.$emit('upload-complete',this.result);
    },
    drop: function (e) {
      this.stopExternalDragging('drop', true);
      let dt = e.dataTransfer;
      let files = dt.files;
      this.uploadFile(files);
    },
    stopExternalDragging: function (name = '', here = false) {
      console.log(name + ' stop ' + !here);
      this.dragging = !here;
      this.$emit('stop-dragging');
    },
  },
}
</script>

<style scoped lang="scss">
.image-uploader-container {
  width: 100%;
  padding: 20px;
  border: 2px solid transparent;
  min-height: 100px;
  border: 2px dashed #B0413E;

  &.dragging, .dragging & {
    background-color: #25d6e5;
  }

  .uploaded-files {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;

    img {
      max-width: 50px;
      max-height: 50px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}
label{
  font-size: 15px;
  display: block;
  margin-bottom: 20px;
}
</style>